.app {
  text-align: center;
  background-color: #f2f2f0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-logo {
  margin: 30px;
  padding: 30px;
  width: 550px;
  pointer-events: none;
}

.app-link {
  color: #61dafb;
}

@font-face {
  font-family: 'Armour Piercing';
  src: url("./assets/fonts/armor-piercing.regular.ttf") format("truetype");
}

.heading-text {
  width:75%;
  text-align: center;
}

.body-text {
  /* max-width: 450px; */
  color: #23305b;
  font-family: "Exo 2";
  font-size:0.8em;
  text-justify: auto;
  padding:0 30px;
}

.flyer-pack {
  margin: 30px 0;
}

.flyer-pack-flyers {
  border: 1px solid #777171;
  border-radius: 5px;
}

.flyer-pack-title {
  margin-left:10px;
  padding:0;
  color: #23305b;
  font-family: "Armour Piercing";
  font-size:medium;
  text-align:left;
}

.flyer-front {
  border-bottom: 2px dashed #777171;
}


